import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Country } from '../models/lookups.models';
import { environment, compnanyCalculatorTypeEnum } from '../../environments/environment';
import { CompnanyCalculatorTypeEnum } from '../models/calculator.models';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable({
  providedIn: 'root',
})

export class LookupService {

  public countries: Country[] = [];

  constructor(private http: HttpClient) {
    if (compnanyCalculatorTypeEnum != CompnanyCalculatorTypeEnum.ROICalculatorInternal) {
      this.getCountries();
    }
  }

  getCountries = (): void => {
    var getCountriesPath = environment.apiRoot + '/GetCountries';
    this.http.get(getCountriesPath).subscribe(
      (result: Country[]) => {
        this.countries = result;
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
