import { Component } from '@angular/core';
import { CalculatorService } from '../services/calculator.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./../app.component.css'],
})

export class HeaderComponent {

  constructor(private calculatorService: CalculatorService) {
  }

  showHederText = (): boolean => {
    return !!this.calculatorService.showHeaderText;
  }
}
