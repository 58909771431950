import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CalculatorService } from '../services/calculator.service'
import { LookupService } from '../services/lookup.service';
import { CalculatorResultData, CompnanyCalculatorTypeEnum } from '../models/calculator.models';
import { Country } from '../models/lookups.models';
import { Router } from '@angular/router';
import { UserInformation } from '../models/calculator.models'
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { environment, compnanyCalculatorTypeEnum } from '../../environments/environment';

@Component({
  selector: 'calculator-details',
  templateUrl: './calculator.details.component.html',
  styleUrls: ['./../app.component.css'],
  encapsulation: ViewEncapsulation.None,
})

export class CalculatorDetailsComponent {

  private withYelloData: CalculatorResultData;
  private withoutYelloData: CalculatorResultData;
  sendMeResultsModalRef: NgbModalRef;
  successfullModalRef: NgbModalRef;
  selectedCountry: string;
  showComplianceChechBox: boolean = false; 

  modalOptions: NgbModalOptions = {
    backdropClass: 'roi-modal-background',
    windowClass: 'roi-modal-content',
    backdrop: 'static',
    keyboard: false
  };

  userForm: FormGroup;
  submitted = false;
  private codeVerification: string;

  constructor(private modalService: NgbModal,
              private calculatorService: CalculatorService,
              private formBuilder: FormBuilder,
              private routerService: Router,
              private lookupService: LookupService) {

    this.calculatorService.showHeaderText = false;

    if (!this.calculatorService.calculatorResults) {
      this.routerService.navigateByUrl('/');
      return; 
    }

    this.withYelloData = this.calculatorService.calculatorResults.withYelloData;
    this.withoutYelloData = this.calculatorService.calculatorResults.withoutYelloData;

    // set default values for public calculator
    if (compnanyCalculatorTypeEnum != CompnanyCalculatorTypeEnum.ROICalculatorInternal) {
      this.modalOptions.size = 'lg';
      this.selectedCountry = 'United States';
    }
  }

  validateVerifiedCode = (control: AbstractControl): { [key: string]: any } => {
    if (control.value.toLowerCase() != this.codeVerification) {
      return { 'notMatch' : 'Value does not match!' };
    }
    return null;
  }

  validateIsCompanyEmail = (control: AbstractControl): { [key: string]: any } => {
    let email = control.value;

    if (!email.endsWith('@' + environment.companyDomain)) {
      return { 'notMatch': 'Value does not match!' };
    }
    return null;
  }


  openSendMeResultsModal = (template: NgbModalRef): void => {
    switch (compnanyCalculatorTypeEnum)
    {
      case CompnanyCalculatorTypeEnum.ROICalculatorInternal:
        {
          this.userForm = this.formBuilder.group({
            firstName: [''],
            lastName: [''],
            email: ['', [Validators.required, Validators.email, this.validateIsCompanyEmail]],
            companyName: [''],
            jobTitle: [''],
            privacyPolicyAgreed: [false],
            receiveMarketingCommunications: [false],
            verifyCode: ['', [Validators.required, this.validateVerifiedCode]]
          });
          break;
        };
      case CompnanyCalculatorTypeEnum.ROICalculatorPublic:
        {
          this.userForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            companyName: [''],
            jobTitle: [''],
            privacyPolicyAgreed: [false, Validators.requiredTrue],
            receiveMarketingCommunications: [false],
            verifyCode: ['', [Validators.required, this.validateVerifiedCode]]
          });
          break;
        };
      default:
        console.error("CompanyCalculatorType is not configured!");
    }

    this.sendMeResultsModalRef = this.modalService.open(template, this.modalOptions);
  }

  sendMeResults = (template: NgbModalRef): void => {
    if (this.userForm.invalid) {
      this.submitted = true;
      return;
    }
    this.sendMeResultsModalRef.close();


    var userInformation: UserInformation = this.userForm.value as UserInformation;

    userInformation.country = this.selectedCountry;

    this.calculatorService.sendMeResults(userInformation);
    this.successfullModalRef = this.modalService.open(template, this.modalOptions);
  }

  changeCountry = (country: Country) => {
    this.selectedCountry = country.countryName;
    this.showComplianceChechBox = country.sendIntegrationResult;
    this.userFormControls.receiveMarketingCommunications.setValue(false);
  }

  onGenerateCode = (code): void => {
    this.codeVerification = code;
  }

  isInternalCalculator = (): boolean => {
    return compnanyCalculatorTypeEnum == CompnanyCalculatorTypeEnum.ROICalculatorInternal;
  } 

  get userFormControls() { return this.userForm.controls; }


  get countries(): Country[] {
    return this.lookupService.countries;
  }

  get totalCostsWithYello(): string {
    return this.calculatorService.getFormatedAndRoundedValue(this.withYelloData.totalCareerEventCosts);
  }

  get totalCostsWithoutYello(): string {
    return this.calculatorService.getFormatedAndRoundedValue(this.withoutYelloData.totalCareerEventCosts);
  }

  get totalSavings(): string {
    return this.calculatorService.getAllSavings();
  }

  get withYellorecruiterTimeToAcquireCandidateData(): string {
    return this.calculatorService.getFormatedAndRoundedValue(this.withYelloData.recruiterTimeToAcquireCandidateData)
  }

  get withYellorecruiterCostToAcquireCandidateData(): string {
    return this.calculatorService.getFormatedAndRoundedValue(this.withYelloData.recruiterCostToAcquireCandidateData)
  }
  get withYellopostEventFollowUpHours(): string {
    return this.calculatorService.getFormatedAndRoundedValue(this.withYelloData.postEventFollowUpHours)
  }
  get withYellopostEventFollowUpCost(): string {
    return this.calculatorService.getFormatedAndRoundedValue(this.withYelloData.postEventFollowUpCost)
  }
  get withYelloprintedCollateralCosts(): string {
    return this.calculatorService.getFormatedAndRoundedValue(this.withYelloData.printedCollateralCosts)
  }

  get withoutYellorecruiterTimeToAcquireCandidateData(): string {
    return this.calculatorService.getFormatedAndRoundedValue(this.withoutYelloData.recruiterTimeToAcquireCandidateData)
  }
  get withoutYellorecruiterCostToAcquireCandidateData(): string {
    return this.calculatorService.getFormatedAndRoundedValue(this.withoutYelloData.recruiterCostToAcquireCandidateData)
  }
  get withoutYellopostEventFollowUpHours(): string {
    return this.calculatorService.getFormatedAndRoundedValue(this.withoutYelloData.postEventFollowUpHours)
  }
  get withoutYellopostEventFollowUpCost(): string {
    return this.calculatorService.getFormatedAndRoundedValue(this.withoutYelloData.postEventFollowUpCost)
  }
  get withoutYelloprintedCollateralCosts(): string {
    return this.calculatorService.getFormatedAndRoundedValue(this.withoutYelloData.printedCollateralCosts)
  }

  get withYelloAnnualTimeSpentCoordinatingInterviews(): string {
    return this.calculatorService.getFormatedAndRoundedValue(this.withYelloData.totalHours);
  }

  get withoutYelloAnnualTimeSpentCoordinatingInterviews(): string {
    return this.calculatorService.getFormatedAndRoundedValue(this.withoutYelloData.totalHours);
  }
}

