import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CalculatorInputModel } from '../models/calculator.models';
import { CalculatorService } from '../services/calculator.service';
import { Router } from '@angular/router';


@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'calculator-fields',
  templateUrl: './calculator.component.html',
  styleUrls: ['./../app.component.css']
})

export class CalculatorComponent {

  recruiterSalary: string;
  costPerCollateralPiece: string;

  constructor(private calculatorService: CalculatorService, private routerService: Router) {
    if (calculatorService.calculatorInputModel && calculatorService.calculatorInputModel.recruiterSalary) {
      this.recruiterSalary = calculatorService.calculatorInputModel.recruiterSalary.toString();
    }

    if (calculatorService.calculatorInputModel && calculatorService.calculatorInputModel.costPerCollateralPiece) {
      this.costPerCollateralPiece = calculatorService.calculatorInputModel.costPerCollateralPiece.toString();
    }

    this.calculatorService.showHeaderText = true;
  }

  calculateResults = (): void => {
    if (this.areAllFieldsFilled) {
      this.calculatorService.calculatorInputModel.costPerCollateralPiece = +(this.costPerCollateralPiece.toString().replace(/[^\d.-]/g, ''));
      this.calculatorService.calculatorInputModel.recruiterSalary = +(this.recruiterSalary.toString().replace(/[^\d.-]/g, ''));

      this.calculatorService.calculateResults(this.calculatorService.calculatorInputModel);
    }
  }

  get numOfAnnualEvents(): number {
    return this.calculatorService.calculatorInputModel.numOfAnnualEvents;
  }
  set numOfAnnualEvents(value: number) {
    this.calculatorService.calculatorInputModel.numOfAnnualEvents = this.removePipes(value.toString());
  }
  get candidatesSeenPerEvent(): number {
    return this.calculatorService.calculatorInputModel.candidatesSeenPerEvent;
  }
  get recruitersPerEvent(): number {
    return this.calculatorService.calculatorInputModel.recruitersPerEvent;
  }
  get minForInputCandidateData(): number {
    return this.calculatorService.calculatorInputModel.minForInputCandidateData;
  }
  get recruitersAssignedForPostCommunication(): number {
    return this.calculatorService.calculatorInputModel.recruitersAssignedForPostCommunication;
  }
  get prePostHours(): number {
    return this.calculatorService.calculatorInputModel.prePostHours;
  }
  set candidatesSeenPerEvent(value: number) {
    this.calculatorService.calculatorInputModel.candidatesSeenPerEvent = this.removePipes(value.toString());
  }
  set recruitersPerEvent(value: number) {
    this.calculatorService.calculatorInputModel.recruitersPerEvent = this.removePipes(value.toString());
  }
  set minForInputCandidateData(value: number) {
    this.calculatorService.calculatorInputModel.minForInputCandidateData = this.removePipes(value.toString());
  }
  set recruitersAssignedForPostCommunication(value: number) {
    this.calculatorService.calculatorInputModel.recruitersAssignedForPostCommunication = this.removePipes(value.toString());
  }
  set prePostHours(value: number) {
    this.calculatorService.calculatorInputModel.prePostHours = this.removePipes(value.toString());
  }
  get numOfAnnualCandidatesSeenPerEvent(): string  {
    if (!!this.candidatesSeenPerEvent && this.numOfAnnualEvents) {
      return String(this.removePipes(this.candidatesSeenPerEvent.toString()) * this.removePipes(this.numOfAnnualEvents.toString()));
    }
    else {
      return '';
    }
  }
  set numOfAnnualCandidatesSeenPerEvent(value: string) {
    this.calculatorService.calculatorInputModel.numOfAnnualCandidatesSeenPerEvent = this.candidatesSeenPerEvent * this.numOfAnnualEvents;
  }

  get areAllFieldsFilled(): boolean {
    return !!this.numOfAnnualEvents &&
      !!this.candidatesSeenPerEvent &&
      !!this.recruitersPerEvent &&
      !!this.minForInputCandidateData &&
      !!this.recruitersAssignedForPostCommunication &&
      !!this.prePostHours &&
      !!this.recruiterSalary &&
      !!this.costPerCollateralPiece;
  }

  get allSavings(): string {
    if (this.areAllFieldsFilled && this.calculatorService.getAllSavings()) {
      return '$' + this.calculatorService.getAllSavings();
    }
    return '';
  }

  gotToDetails = (): void => {
    if (this.areAllFieldsFilled) {
      this.routerService.navigateByUrl('/details');
    }
  }

  private removePipes = (value: string): number => {
    return +(value.toString().replace(/[^\d.-]/g, ''));
  }

}

