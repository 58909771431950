import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { CalculatorResultModel, CalculatorInputModel, CalculatorResultData, SendResultsModel, UserInformation, ExternalData, ICalculator, GlobalCalculatorTypeEnum } from '../models/calculator.models';
import { environment, compnanyCalculatorTypeEnum, globalCalculatorTypeEnum } from '../../environments/environment';
import { InterviewSchedulingCalculator } from './calculators/interviewscheduling.calculator';
import { RecruitmentEventCalculator } from './calculators/recruitmentevent.calculator';
import { ConfigurationService } from './configuration.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable({
  providedIn: 'root',
})

export class CalculatorService {

  public calculatorResults: CalculatorResultModel;
  public calculatorInputModel: CalculatorInputModel = this.configurationService.getDefaultInputModel(); // new CalculatorInputModel(30, 0, 100, 2, 10, 2, 16, 60000, 2, compnanyCalculatorTypeEnum);
  private externalData: ExternalData = { GCLID__c: '-', utm_campaign__c: 'https://yello.co/privacy-policy/', utm_medium__c: 'organic', utm_source__c: 'google', utm_term__c: '-' };
  private _showHeaderText: boolean;

  constructor(private http: HttpClient, private configurationService: ConfigurationService) {
    this.calculateResults(this.calculatorInputModel);
  }

  public calculateResults = (inputData: CalculatorInputModel): void => {
    this.calculateData(inputData);
  }

  calculateData = (inputData: CalculatorInputModel): void => {
    this.calculateClient(inputData);
  }

  sendMeResults = (userInformation: UserInformation): void => {
    var request: SendResultsModel = { userInformation: userInformation, calculatorInputModel: this.calculatorInputModel, globalCalculatorType: globalCalculatorTypeEnum, externalData: this.externalData };

    var calculatePath = environment.apiRoot + '/SendMeResults';
    this.http.post(calculatePath, JSON.stringify(request), httpOptions).subscribe(
      (result: boolean) => {
      },
      (error) => {
        console.log(error);
      }
    );
  }

  get showHeaderText(): boolean { return this._showHeaderText };
  set showHeaderText(value: boolean) { this._showHeaderText = value };

  public getAllSavings = (): string => {
    if (!!this.calculatorResults) {
      var result = this.calculatorResults.withoutYelloData.totalCareerEventCosts - this.calculatorResults.withYelloData.totalCareerEventCosts;
      return this.getFormatedAndRoundedValue(result);
    }
  }

  calculateClient = (calculatorInputModel: CalculatorInputModel): void => {
    this.calculatorResults = this.getCalculatorInstance().calculate(calculatorInputModel);
  }

  getFormatedAndRoundedValue = (value: number): string => {
    if (!value) {
      return "0";
    }

    var result: string = Math.round(value).toString();
    return result.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  private getCalculatorInstance = (): ICalculator => {
    switch (globalCalculatorTypeEnum) {
      case GlobalCalculatorTypeEnum.InterviewScheduling:
        return new InterviewSchedulingCalculator;
      case GlobalCalculatorTypeEnum.RecruitersEvents:
        return new RecruitmentEventCalculator;
      default:
        throw "GlobalCalculator Type is not configured properly!";
    }
  }

}
