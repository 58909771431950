/**** Modules ****/
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CaptchaModule } from 'ng-captcha';

/**** Components ****/
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { CalculatorComponent } from './calculator/calculator.component';
import { CalculatorDetailsComponent } from './calculator-details/calculator.details.component';
import { ROICheckBox } from './common/checkbox/roicheckbox.component';

/**** Directives *********/
import { CurrencyPipe } from './common/currency.pipe';
import { OnlyNumber } from './common/allownumbers.directive';
import { ConfigurablePipe } from './common/configurable.pipe';
import { CheckVisibilityFromConfigPipe } from './common/checkvisibilityfromconfig.pipe';


import { ConfigurationService } from './services/configuration.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    CalculatorComponent,
    CalculatorDetailsComponent,
    CurrencyPipe,
    ConfigurablePipe,
    CheckVisibilityFromConfigPipe,
    OnlyNumber,
    ROICheckBox
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    MatInputModule,
    MatIconModule,
    NgbModule,
    MatTooltipModule,
    ReactiveFormsModule,
    CaptchaModule,
    RouterModule.forRoot([
      { path: '', component: CalculatorComponent, pathMatch: 'full' },
      { path: 'details', component: CalculatorDetailsComponent }
    ])
  ],
  providers: [ConfigurationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
