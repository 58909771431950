export interface ICalculator {
  calculate(calculatorInputModel: CalculatorInputModel): CalculatorResultModel;
}

export class CalculatorInputModel {
  constructor(
    public numOfAnnualEvents?: number,
    public numOfAnnualCandidatesSeenPerEvent?: number,
    public candidatesSeenPerEvent?: number,
    public recruitersPerEvent?: number,
    public minForInputCandidateData?: number,
    public recruitersAssignedForPostCommunication?: number,
    public prePostHours?: number,
    public recruiterSalary?: number,
    public costPerCollateralPiece?: number,
    public companyCalculatorType?: CompnanyCalculatorTypeEnum
  ) { }
}

export class CalculatorResultData {
  constructor(
    public recruiterTimeToAcquireCandidateData: number,
    public recruiterCostToAcquireCandidateData: number,
    public postEventFollowUpHours: number,
    public postEventFollowUpCost: number,
    public printedCollateralCosts: number,
    public totalCareerEventCosts: number,
    public totalHours: number
  ) { }
}

export class CalculatorResultModel {
  constructor(
    public withYelloData: CalculatorResultData,
    public withoutYelloData: CalculatorResultData,
  ) { }
}

export class UserInformation {
  constructor(
    public firstName: string,
    public lastName: string,
    public email: string,
    public companyName: string,
    public jobTitle: string,
    public country: string,
    public privacyPolicyAgreed: string,
    public receiveMarketingCommunications: boolean
  ) { }
}

export class SendResultsModel {
  constructor(
    public userInformation: UserInformation,
    public calculatorInputModel: CalculatorInputModel,
    public globalCalculatorType: GlobalCalculatorTypeEnum, 
    public externalData: ExternalData
  ) { }
}

export class ExternalData {
  constructor(
    public utm_campaign__c: string,
    public utm_medium__c: string,
    public utm_source__c: string,
    public utm_term__c: string,
    public GCLID__c: string,
  ) { }
}

export enum CompnanyCalculatorTypeEnum {
  ROICalculatorPublic = 1,
  ROICalculatorInternal = 2
}

export enum GlobalCalculatorTypeEnum {
  RecruitersEvents = 1,
  InterviewScheduling = 2
}
