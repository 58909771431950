import { Pipe, PipeTransform } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';

@Pipe({ name: 'checkIfVisibleInConfig', pure: true })

export class CheckVisibilityFromConfigPipe implements PipeTransform {

  constructor(private configurationService: ConfigurationService) { }

  transform(value: any, args?: any): boolean {
    return this.configurationService.readVisibilityConfiguration(value);
  }

}
