import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { retry } from 'rxjs/operators';

@Pipe({ name: 'currencyPipe' })
export class CurrencyPipe implements PipeTransform {
  transform(value: string, showDolar?: boolean): string {
    if (!value) {
      return value;
    }

    if (value == '$') {
      return '';
    }

    value = value.toString().replace(/[^\d.-]/g, '');

    var startString: string = showDolar ? "$" : "";

    return startString + (+value).toLocaleString('en-us', { maximumFractionDigits: 2 });
    
  }
}
