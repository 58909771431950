import { Injectable } from '@angular/core';
import { CalculatorResultModel, CalculatorInputModel, CalculatorResultData, ICalculator } from '../../models/calculator.models';


export class InterviewSchedulingCalculator implements ICalculator {
  constructor() { }

  calculate = (calculatorInputModel: CalculatorInputModel): CalculatorResultModel => {
    var withYello = new CalculatorResultData(0, 0, 0, 0, 0, 0, 0);
    var withoutYello = new CalculatorResultData(0, 0, 0, 0, 0, 0, 0);

    withoutYello.totalHours = Math.round((calculatorInputModel.numOfAnnualEvents * calculatorInputModel.minForInputCandidateData) / 60);
    withoutYello.totalCareerEventCosts = Math.round(withoutYello.totalHours * calculatorInputModel.prePostHours);

    withYello.totalHours = Math.round((calculatorInputModel.numOfAnnualEvents * 5.0) / 60);
    withYello.totalCareerEventCosts = Math.round(withYello.totalHours * calculatorInputModel.prePostHours);

    return new CalculatorResultModel(withYello, withoutYello);
  }
}
