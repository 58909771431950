import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';

@Pipe({ name: 'readFromConfig', pure: false })

export class ConfigurablePipe implements PipeTransform {

  constructor(private configurationService: ConfigurationService) { }

  transform(value: any, args?: any): any {
    return this.configurationService.readTextFromConfiguration(value);
  }

}
