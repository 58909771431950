import { Injectable } from '@angular/core';
import { globalCalculatorTypeEnum, compnanyCalculatorTypeEnum } from '../../environments/environment';
import { GlobalCalculatorTypeEnum, CalculatorInputModel } from '../models/calculator.models';
import { retry } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})

export class TranslationSet {
  public calculatorType: GlobalCalculatorTypeEnum
  public values: { [key: string]: any } = {}
}

export class ConfigurationService {
  calcTypeString: string; 


  private textDictionary: { [key: string]: TranslationSet } = {
    InterviewScheduling: {
      calculatorType: GlobalCalculatorTypeEnum.InterviewScheduling,
      values: {
        headerTitle: 'Interview Scheduling ROI Calculator',
        headerText: "Customize the sample data below with your company's statistics to uncover your interview scheduling ROI and drive hard cost savings.",
        annualFieldLabel: '# of Annual Interviews Scheduled:',
        annualFieldTooltip: 'Interviews include phone screens, onsite interview days, panels, etc.',
        averageMinutesPerEventLabel: 'Average # of Minutes Spent Coordinating the Interview',
        averageMinutesPerEventTooltip: 'Time it takes to check calendars, send email communications and confirmations',
        calcResultsTitle: 'Total Interview Scheduling Costs, with and without Yello',
        calcInputPageTitle: 'Total Interview Scheduling Costs',
        calcInputPageDesc: 'Includes recruiting coordinator salary and time spent scheduling interviews',
        bucketCopyLOneTitle: 'Build stronger relationships',
        bucketCopyLOneDesc: 'Focus on connections, not manual work',
        bucketCopyROneTitle: 'Stay ahead of the competition',
        bucketCopyROneDesc: 'Schedule smarter to get offers out faster',
        bucketCopyLTwoTitle: 'Empower candidates',
        bucketCopyLTwoDesc: 'Save time with streamlined self-scheduling',
        bucketCopyRTwoTitle: 'Engage from anywhere',
        bucketCopyRTwoDesc: 'Remove barriers with mobile and video',
      },
    },
    RecruitersEvents: {
      calculatorType: GlobalCalculatorTypeEnum.RecruitersEvents,
      values: {
        headerTitle: 'Recruitment Event ROI Calculator',
        headerText: "Customize the sample data below with your company's statistics to uncover how Yello can maximize your recruitment event ROI and drive hard cost savings.",
        annualFieldLabel: '# of Annual Recruitment Events:',
        annualFieldTooltip: 'Recruiting events include career fairs, diversity conferences, candidate open houses, etc.',
        averageMinutesPerEventLabel: "Avg # of Minutes necessary to Input Each Candidate's Data:",
        averageMinutesPerEventTooltip: 'Time to take transpose candidate notes and resume into a centralized place (spreadsheet or system)',
        calcResultsTitle: 'Total Recruitment Event Costs, with and without Yello',
        calcInputPageTitle: 'Total Recruitment Events Costs',
        calcInputPageDesc: 'Includes Recruiter salary, collateral & follow-up',
        bucketCopyLOneTitle: 'Make data-driven investments',
        bucketCopyLOneDesc: 'Track ROI by event to make informed decisions',
        bucketCopyROneTitle: 'Stay ahead of the competition',
        bucketCopyROneDesc: 'Work smarter to find top talent before they do',
        bucketCopyLTwoTitle: 'Impress every candidate',
        bucketCopyLTwoDesc: 'Personalize engagement and hire faster',
        bucketCopyRTwoTitle: 'Get peace of mind',
        bucketCopyRTwoDesc: 'Support Compliance and internal alignment',
      },
    },
  }

  private visibilityDictionary: { [key: string]: TranslationSet } = {
    InterviewScheduling: {
      calculatorType: GlobalCalculatorTypeEnum.InterviewScheduling,
      values: {
        avgCoordinatorHourlyRate: true,
        interviewResults: true,
      },
    },
    RecruitersEvents: {
      calculatorType: GlobalCalculatorTypeEnum.RecruitersEvents,
      values: {
        candidatesSeenPerEvent: true,
        avgAttendersToEvent: true,
        avgAssignedToEventComunication: true,
        avgHoursPrePostEventActivity: true,
        avgSalary: true,
        costCollateralPiece: true,
        recruitmentResults: true
      },
    },
  }

  constructor() {
    this.calcTypeString = this.setcalculatorTypeString();
  }

  readTextFromConfiguration = (key: string): string => {
    if (this.textDictionary[this.calcTypeString] != null) {
      return this.textDictionary[this.calcTypeString].values[key];
    }
  }

  readVisibilityConfiguration = (key: string): boolean => {
    return !!this.visibilityDictionary[this.calcTypeString] && this.visibilityDictionary[this.calcTypeString].values[key];
  }



  setcalculatorTypeString = () => {
    switch (globalCalculatorTypeEnum) {
      case GlobalCalculatorTypeEnum.RecruitersEvents:
        return 'RecruitersEvents';
      case GlobalCalculatorTypeEnum.InterviewScheduling:
        return 'InterviewScheduling';
      default:
        throw "Invalid Calc Type!";
    }
  }

  getDefaultInputModel = (): CalculatorInputModel => {
    switch (globalCalculatorTypeEnum) {
      case GlobalCalculatorTypeEnum.InterviewScheduling:
        return new CalculatorInputModel(3000, 1, 1, 1, 45, 1, 25.71, 1, 1, compnanyCalculatorTypeEnum);
      case GlobalCalculatorTypeEnum.RecruitersEvents:
        return new CalculatorInputModel(30, 0, 100, 2, 10, 2, 16, 60000, 2, compnanyCalculatorTypeEnum);
      default:
        throw "Calculator type is not configure properly!";
    }
  }
}
