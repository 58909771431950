import { Injectable } from '@angular/core';
import { CalculatorResultModel, CalculatorInputModel, CalculatorResultData, ICalculator } from '../../models/calculator.models';


export class RecruitmentEventCalculator implements ICalculator {

  public calculatorResults: CalculatorResultModel;

  constructor() {}

  calculate = (calculatorInputModel: CalculatorInputModel): CalculatorResultModel => {
    var withYello = new CalculatorResultData(0, 0, 0, 0, 0, 0, 0);
    var withoutYello = new CalculatorResultData(0, 0, 0, 0, 0, 0, 0);

    var numOfAnnualCandidatesSeenPerEvent: number = calculatorInputModel.candidatesSeenPerEvent * calculatorInputModel.numOfAnnualEvents;
    var recruiterSalaryPerMin: number = ((calculatorInputModel.recruiterSalary * 1.2) / 2080) / 60;
    var recruiterSalaryPerHr: number = ((calculatorInputModel.recruiterSalary * 1.2) / 2080);

    withoutYello.recruiterTimeToAcquireCandidateData = Math.round((numOfAnnualCandidatesSeenPerEvent * calculatorInputModel.minForInputCandidateData) / 60);

    withoutYello.recruiterCostToAcquireCandidateData = Math.round(numOfAnnualCandidatesSeenPerEvent * (calculatorInputModel.minForInputCandidateData * recruiterSalaryPerMin));

    withoutYello.postEventFollowUpHours = Math.round(calculatorInputModel.numOfAnnualEvents * calculatorInputModel.recruitersAssignedForPostCommunication * calculatorInputModel.prePostHours);

    withoutYello.postEventFollowUpCost = Math.round(withoutYello.postEventFollowUpHours * recruiterSalaryPerHr);

    withoutYello.printedCollateralCosts = Math.round(numOfAnnualCandidatesSeenPerEvent * calculatorInputModel.costPerCollateralPiece);

    withoutYello.totalCareerEventCosts = withoutYello.recruiterCostToAcquireCandidateData + withoutYello.postEventFollowUpCost + withoutYello.printedCollateralCosts;

    withYello.postEventFollowUpHours = Math.round((calculatorInputModel.numOfAnnualEvents * 30) / 60);

    withYello.postEventFollowUpCost = Math.round(withYello.postEventFollowUpHours * recruiterSalaryPerHr);

    withYello.totalCareerEventCosts = withYello.recruiterCostToAcquireCandidateData + withYello.postEventFollowUpCost + withYello.printedCollateralCosts;

    return new CalculatorResultModel(withYello, withoutYello);
  }
}
