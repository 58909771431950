import {
  Component,
  OnInit,
  Input,
  ViewChild,
  forwardRef
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  NgModel,
  ControlValueAccessor
} from '@angular/forms';

@Component({
  selector: 'roi-checkbox',
  templateUrl: './roicheckbox.template.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ROICheckBox),
    multi: true
  }]
})
  

export class ROICheckBox implements OnInit, ControlValueAccessor {

  isSelected: boolean;
  _showError: boolean;

  writeValue(obj: any): void {
    this.isSelected = obj;
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  private _onChange = (_: any) => { };
  private _onTouched = () => { };

  @Input()
  set showError(value: boolean) {
    this._showError = value;
  }

  get showError(): boolean { return this._showError; }

  ngOnInit(): void {
    this.isSelected = false;
    this.showError = false;
  }

  constructor() {

  }

  toggleCheckbox() {
    this.isSelected = !this.isSelected;
    this._onChange(this.isSelected);
  }
  
}

